import ContentfulImage from '@/components/contentful-image';
import { JobItemIC, JobsIC } from '@/lib/interface-types';
import PlusGreenImage from '@/public/icons/add-plus-green.svg';
import PlusImage from '@/public/icons/add-plus-icon.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import JobForm from '../job-form';
import PersonBanner from '../person-banner';
import JobItem from './job-item';
import SelectBox from './select-box';

const Jobs = ({ count, bannerData }: JobsIC) => {
  const [location, setLocation] = useState('All Locations');
  const [role, setRole] = useState('All Roles');
  const [allJobs, setAllJobs] = useState<JobItemIC[]>([]);
  const [pageModule, setPageModule] = useState(0);
  const [dataBanner, setDataBanner] = useState(null);
  const [moreJobs, setMoreJobs] = useState(6);
  const [page, setPage] = useState(100);
  const [locationFilter, setLocationFilter] = useState('');
  const [positionFilter, setPositionFilter] = useState('');
  const [filteredJobs, setFilteredJobs] = useState<JobItemIC[]>([]);
  const [showForm, setShowForm] = useState(false);

  const { t } = useTranslation();
  const locations = [
    'All Locations',
    'Zrenjanin',
    'Novi Sad',
    'Porto',
    'Lucerne',
    'Munich',
    'Zurich',
    'Belgrade',
    'Rhine Valley',
    'Dornbirn',
    'Bern'
  ];
  const positions = [
    'All Roles',
    'IT',
    'Project Management',
    'Quality Assurance',
    'Talent Acquisition',
    'UX/UI',
    'Business Administration',
    'Business Development'
  ];

  const jobLocation = location !== 'All Locations' ? location : '';
  const jobRole = role !== 'All Roles' ? role : '';

  const hasMoreData = allJobs.length > moreJobs;

  const filterHandler = (data: JobItemIC[]) => {
    let filteredData = data;

    if (jobLocation) {
      filteredData = filteredData.filter((job) =>
        job.locations.some((l) => l.name.includes(jobLocation))
      );
    }

    if (jobRole)
      filteredData = filteredData.filter((job) =>
        job.tags?.some((tag) => tag.includes(`Career ${role}`))
      );

    setFilteredJobs(filteredData);
  };

  useEffect(() => {
    const fetchJobs = async () => {
      const url = `https://consulteer1.recruitee.com/api/offers/`;
      const options = {
        method: 'GET',
        headers: {
          accept: 'application/json'
        }
      };
      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        const result = await response.json();
        setAllJobs(result.offers);
        setMoreJobs(6);
      } catch (error) {
        return <div>No data</div>;
      }
    };

    !allJobs.length && fetchJobs();
    filterHandler(allJobs);
  }, [role, location]);

  useEffect(() => {
    const sortedData = allJobs?.sort((a: any, b: any) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      return dateB.getTime() - dateA.getTime();
    });
    setFilteredJobs(sortedData);
  }, [allJobs]);

  const handleLoadMore = (
    pageNum: number,
    roleCurrent: string,
    locationCurrent: string
  ) => {
    setPage(pageNum);
    setLocationFilter(
      locationCurrent === 'All Locations' ? '' : locationCurrent
    );
    setPositionFilter(roleCurrent === 'All Roles' ? '' : roleCurrent);
  };

  const handleApplyClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleLocationSelectChange = (event: string) => {
    setLocation(event);
    handleLoadMore(6, role, event);
  };
  const handleRoleSelectChange = (event: string) => {
    setRole(event);
    handleLoadMore(6, event, location);
  };
  const loadMoreClick = () => {
    setLocationFilter(location === 'All Locations' ? '' : location);
    setPositionFilter(role === 'All Roles' ? '' : role);
    setMoreJobs(moreJobs + 6);
  };

  useEffect(() => {
    const locationExists = bannerData?.some((banner: any) => {
      return banner.locations && banner.locations.includes(location);
    });

    if (locationExists) {
      const matchingBanners = bannerData.filter((banner: any) => {
        return banner.locations && banner.locations.includes(location);
      });

      setDataBanner(matchingBanners[0]);
    }
  }, [bannerData, location]);

  return (
    <div className="Jobs mt-20">
      <div className="lg:flex lg:justify-between lg:items-center lg:my-8">
        <h2>
          {t('OpenPosition')} <span className="text-brand-green">{count}</span>
        </h2>
        <div className="flex justify-between lg:justify-end sm:justify-start my-4 lg:my-0">
          <SelectBox
            onChange={handleRoleSelectChange}
            items={positions}
            className="mr-5 w-[230px]"
          />
          <SelectBox
            onChange={handleLocationSelectChange}
            items={locations}
            className="ml-5 w-[200px] text-xl"
          />
        </div>
      </div>

      {!!filteredJobs.length && (
        <div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14"
          id="allJobs"
        >
          {filteredJobs.slice(0, moreJobs).map((job: JobItemIC) => (
            <div
              key={uuid()}
              className="hover:outline-[#3cd52e] hover:outline hover:outline-[4px] shadow-[-2px_-2px_8px_rgba(209,209,209,0.5)] h-[380px] bg-white flex flex-col"
            >
              <JobItem item={job} />
            </div>
          ))}
        </div>
      )}
      {!filteredJobs.length && (
        <h3 className="text-center">
          {t('topCareers.no_jobs_for_current_positions')}
        </h3>
      )}

      {hasMoreData && (
        <div
          className="flex items-center justify-items-center	 cursor-pointer font-bold mt-10 group w-fit"
          onClick={() => loadMoreClick()}
          aria-hidden="true"
        >
          <p className="uppercase show-more mt-2 mr-2 group-hover:text-brand-green">
            {t('topCareers.show_more_jobs')}
          </p>
          <div className="hidden group-hover:flex">
            <ContentfulImage
              src={PlusGreenImage}
              width={24}
              height={24}
              alt="Load more"
            />
          </div>
          <div className="group-hover:hidden">
            <ContentfulImage
              src={PlusImage}
              width={24}
              height={24}
              alt="Load more"
            />
          </div>
        </div>
      )}

      <button
        className="green-button ml-2 mt-10 group"
        type="button"
        onClick={handleApplyClick}
      >
        <p className="group-hover:text-brand-green">
          {t('topCareers.quick_apply')}
        </p>
      </button>
      <div className={`${showForm ? 'visable' : 'invisible'}`}>
        <JobForm
          id="6275578027cd7"
          onClose={handleCloseForm}
          slug="spontaneous-application"
        />
      </div>

      {dataBanner && (
        <div>
          <PersonBanner data={dataBanner} sectionbaner={false} />
        </div>
      )}
    </div>
  );
};

export default Jobs;
