import Card from '@/ctf-components/card/card';
import { CardSectionIC, SectionLayoutVariantsIC } from '@/lib/interface-types';
import uuid from 'react-uuid';
import AccordionSection from './accordion-section';

const CardSection = ({ section, slug }: CardSectionIC) => {
  const { title, sectionContentCollection, sectionPadding } = section;
  const layoutControl: SectionLayoutVariantsIC = {
    '1/3 1/3 1/3': 'mt-8 grid sm:grid-cols-2 xl:grid-cols-3 gap-12 xl:gap-[3.75rem]',
    '1/1': 'grid gap-y-16',
    '1/3 2/3':
      'mt-8 grid md:grid-cols-3 lg:grid-cols-3 gap-12 xl:gap-[3.75rem]',
    '1/2 1/2': 'grid md:grid-cols-2 gap-12 xl:gap-[3.75rem]',
    'Accordion left - Article': 'left',
    'Accordion left - Category Page': 'left',
    'Accordion right - Article': 'right',
    'Accordion right - Category Page': 'right',
    '1/2 1/2 - simple-card-link' : ''
  };

  const hasAccordion = sectionContentCollection.items.some(
    (item) => item.__typename === 'Accordion'
  );

  if (hasAccordion) {
    return (
      <AccordionSection
        title={title}
        items={sectionContentCollection.items.filter(
          (item) => item.__typename === 'Accordion'
        )}
        item={sectionContentCollection.items.find((item) => item.__typename === 'Card')}
        layout={layoutControl[section.layout]}
      />
    );
  }
  const isSimpleCardIconLink = sectionContentCollection.items.every(
    (item) => item.cardTemplate === 'card-simple-icon-link'
  );

  return (
    <section key={uuid()} className="CardSection w-full mt-12 lg:mt-20">
      {title && !isSimpleCardIconLink && (
        <h2 className={`mb-6 lg:mb-10 ${sectionPadding ? 'mx-4' : ''}`}>{title}</h2>
      )}
      <div>
        {isSimpleCardIconLink ? (
          <div className="flex flex-col lg:flex-row gap-10 lg:gap-20 xl:gap-32">
            <h2>{title}</h2>
            <section
              className={`col-span-3 ${
                layoutControl[section.layout]
              } grow-2 grid grid-cols-1 sm:grid-cols-2 gap-6 lg:gap-14`}
              style={{ flexGrow: 3, flexBasis: 0 }}
            >
              {sectionContentCollection.items.map(
                  (item, index) =>
                    !item.description && (
                      <Card
                        key={uuid()}
                        card={item}
                        slug={slug}
                        classIndex={index}
                        sectionLayout={section.layout}
                        sectionPadding={sectionPadding}
                      />
                    )
                )}
            </section>
          </div>
        ) : (
          <section
            className={`col-span-3 ${
              layoutControl[section.layout]
            }`}
          >
            {sectionContentCollection.items.map(
                (item, index) =>
                  !item.description && (
                    <Card
                      key={uuid()}
                      card={item}
                      slug={slug}
                      classIndex={index}
                      sectionLayout={section.layout}
                      sectionPadding={sectionPadding}
                    />
                  )
              )}
          </section>
        )}
      </div>
    </section>
  );
};

export default CardSection;
