export const slugify = (string: string) => {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

export const extractFirstSentence = (text: string) => {
  return text.split(/([.!?])/).reduce((acc, part, index, array) => {
    if (index === 0 && array[index + 1]) {
      acc = part + array[index + 1];
    }
    return acc;
  }, '');
};
