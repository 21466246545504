import Link from 'next/link';
import ContentfulImage from '@/components/contentful-image';
import { ArticleFeaturedProps } from '@/lib/interface-types';

const ArticleNoHover = ({ article }: ArticleFeaturedProps) => {
    const articleUrl = article.category
    ? `/${article.category.toLowerCase()}/${article.slug}`
    : `/${article.slug}`;
  return (
    <Link href={articleUrl}>
      <div className="relative h-[330px] min-[1700px]:h-[400px] overflow-hidden">
        {article.featuredImage?.url && (
          <ContentfulImage
            alt={article?.featuredImage?.title}
            className="ArticleFeaturedImage scale-100 hover:scale-125 duration-500 object-cover"
            src={article?.featuredImage?.url}
            fill
            sizes="99.99vw"
          />
        )}
      </div>
      <div>
        {article?.featuredTitle && (
          <h6 className="mt-2 w-fit font-sans text-base uppercase relative overflow-hidden inline-block">
            {article?.featuredTitle}
            <span className="absolute inset-x-0 bottom-0 h-0.5 bg-black transition-transform duration-500 transform -translate-x-full group-hover:translate-x-0"></span>
          </h6>
        )}
      </div>
    </Link>
  );
};

export default ArticleNoHover;
